$defaultTextColor: #202223;
$blackTextColor: #333333;

$textDisabled: #8c9196;
$textGray: #e7e1e1;
$textGrayBold: #999999;

$mainDefaultColor: #fff;

$sectionTitleColor: #767676;

$primaryLinksBg: #008060;

$iconSuccess: #007f5f;

$successLight: #00a47c;

$sectionPrimaryBg: #ecf6f1;

$subheaderColor: #676767;
$secondGreyColor: #6d7175;
