@use "styles/variables/colors/colors";
@use "styles/mixins/setFonts" as *;

.lang-switcher {
  &__container {
    position: relative;
    margin-bottom: 50px;
  }

  &__list {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px;
    left: 0;
    bottom: 100%;
    min-width: 160px;
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 150ms;
  }

  &__item {
    transition: background-color 200ms ease-in-out;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #ebebee;
    }
  }

  &__link {
    @include setFonts("Roboto", 0.875rem, 1.25rem, 400);
    text-decoration: none;
    padding: 10px 8px;
    display: block;
    color: colors.$defaultTextColor;
  }

  &__btn {
    @include setFonts("Ubuntu", 0.875rem, 1.25rem, 700);
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    padding: 5px 20px 5px 5px;
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      position: absolute;
      content: "";
      top: calc(50% - 2.5px);
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #ffffff transparent transparent transparent;
    }
  }
}

.lang-switcher--active {
  .lang-switcher__list {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, -8%, 0);
  }

  .lang-switcher__btn {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.color--dark {
  .lang-switcher__btn {
    color: #202223;
    &:after {
      border-color: #202223 transparent transparent transparent;
    }
  }
}

.global {
  display: flex;
  align-items: center;
  margin-right: 10px;
}