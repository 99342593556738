@use "styles/variables/colors/colors";
@use "styles/variables/components/header/header";
@use "styles/mixins/setFonts" as *;
.navigation {
  display: flex;

  &__block {
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__header-text {
    position: relative;
    color: colors.$defaultTextColor;
    display: inline-block;
    padding: 3px 20px 3px 3px;
    cursor: pointer;
    @include setFonts('Roboto', 0.875rem, 1.25rem, 500);

    &:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: calc(50% - 3.5px);
      right: 0;
      width: 12px;
      height: 7px;
      background-image: url("/common/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  &__list {
    z-index: 1;
    position: absolute;
    min-width: 130px;
    top: 100%;
    left: 0;
    padding: 0 0 0 16px;
    border-radius: header.$navBlockListRadius;
    background-color: colors.$mainDefaultColor;
    display: flex;
    flex-direction: column;
    box-shadow: header.$navBlockListBoxShadow;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition-property: visibility, max-height, padding-bottom, padding-top;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
  }

  &__link {
    text-decoration: none;
    color: colors.$defaultTextColor;
    @include setFonts('Roboto', 0.875rem, 1.25rem, 400);
  }

}

a {
  .navigation {
    &__header-text {
      padding: 3px;
      &:after {
        display: none;
      }
    }
  }
}

.navigation__block:hover .navigation__list{
  visibility: visible;
  padding: 18px 0 18px 16px;
  max-height: initial;
}

// navBar
.navBar {
  z-index: 1000;
  position: fixed;
  max-width: 428px;
  width: 100%;
  height: 100%;
  background-color: #191C34;
  top: 0;
  right: 0;
  padding: 25px 45px 45px 45px;
  transition: transform 0.5s ease-in-out;
  transform: translateX(500px);

  &Close {
    display: inline-block;
    cursor: pointer;
    width: 32px;
    height: 32px;
    transition-property: all;
    transition: 0.4s all;
    transition-delay: 0.4s;
    position: relative;

    &Row1, &Row2{
      display: inline-block;
      position: absolute;
      width: 42px;
      height: 4px;
      background-color: #FFC20A;
      top: calc(50% - 2px);
      left: -5px;
      transition: 0.5s all;
      transition-delay: 0.9s;
      border-radius: 25px;
    }
  }

  &Content {
    margin-top: 40px;
    margin-bottom: 46px;
    &List {
      padding-left: 56px;
    }

    &Item {
      margin-bottom: 15px;
    }

    &Link {
      color: #FFFFFF;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.8;
    }
  }
}


.navBarActive {
  transform: translateX(0px);

  .navBarClose {
    transform: rotate(360deg);
    &Row1 {
      transform: rotate(45deg);
    }
    &Row2 {
      transform: rotate(-45deg);
    }
  }


}

.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transform: translateX(-100%);
  transition: 0.3s transform ease-in-out;
}

.overlayActive {
  transform: translateX(0);
}


@media screen and (max-width: 340px) {
  .navBar {
    padding: 25px 35px 35px 35px;
  }
}