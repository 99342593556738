@use "styles/variables/index" as *;

.header {
  padding: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;
  background-color: #FFFFFF;
  z-index: 100;

  &__logo {
    width: 160px;
    outline: none;
    margin-right: 100px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
  }

  &__navigation {
    display: flex;
    align-items: center;
  }

  &__external-links {
    :global {
      .Polaris-Icon {
        display: flex;
      }
    }
  }
}

.logo-link {
  max-width: 200px;
  width: 100%;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
}


@media screen and (min-width: ($widthForNavBar)) {
  .sidebar-wrapper {
    display: none;
  }
}

@media screen and (max-width: ($widthForNavBar - 1)) {
  .header {
    &__navigation-wrapper {
      display: none;
    }

    &__external-links {
      display: none;
    }
  }
}
