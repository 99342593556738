.collapsible {
  &__activator {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.75rem;
    color: #202223;
    margin-bottom: 20px;
    border: none;
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }

  &__link {
    text-decoration: none;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.25rem;
    color: #6D7175;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 13px;
    padding-top: 13px;
    border-left: 1px solid silver;
    margin-bottom: 25px;
  }

  &__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.arrow-wrapper {
  transform: rotate(0deg);
  transition: 350ms transform ease-in-out;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow--active {
  transform: rotate(180deg);
}

.activator--disabled {
  opacity: 0.6;
}

