.sidebar-activator {
  &__burger {
    border: none;
    padding: 5px;
    background: transparent;

    &-container {
      width: 30px;
      height: 24px;
      position: relative;
      display: block;

      &:before, &:after {
        content: "";
        left: 0;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    &-stick {
      left: 0;
      top: calc(50% - 1.5px);
    }
  }

  &__burger-stick, &__burger-container:before, &__burger-container:after {
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #5C5F62;
    border-radius: 50px;
  }
}

.sidebar {
  z-index: 100;
  &__overlay {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    visibility: hidden;
    transition-property: visibility, background-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
  }
}

.sidebar {
  z-index: 101;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  visibility: hidden;
  transition-property: visibility;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  overflow-y: scroll;

  &__content {
    z-index: 11;
    width: 100%;
    background-color: #fff;
    min-height: 100%;
    padding: 25px 30px 30px;
    transform: translateX(350px);
    visibility: hidden;
    transition-property: visibility, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 300ms;
  }

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  &__close {
    border: none;
    padding: 5px;
    background-color: transparent;
  }

  &__close-stick {
    width: 30px;
    height: 24px;
    position: relative;

    &:before, &:after {
      position: absolute;
      content: "";
      width: 30px;
      height: 3px;
      border-radius: 50px;
      background-color: #5C5F62;
      left: 0;
      top: calc(50% - 1.5px);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.sidebar--active {
  visibility: visible;
}

.sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid silver;
  padding-bottom: 8px;
  margin-bottom: 30px;
}

.sidebar--active  .sidebar__content {
  visibility: visible;
  transform: translateX(0);
}
.overlay--active {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}

.button-group {
  padding-top: 10px;
  border-top: 1px solid silver;

  :global {
    .Polaris-Icon {
      display: flex;
    }
  }
}

.logo-link {
  max-width: 200px;
  display: block;

  svg {
    width: 100%;
    height: auto;
  }
}

.lang-switcher-wrapper {
  border-top: 1px solid silver;
  padding-top: 30px;
  margin-top: 20px;
  * {
    fill: #202223;
  }
}